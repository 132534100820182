/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

*:focus:focus-visible {
  outline-style: none !important;
}

#root {
  /* width: 100vw; */
  height: 100vh;
  background-color: #76767608;
}

.euiFlexGroup--gutterSmall,
.euiFlexGroup--gutterLarge {
  margin: 0 !important;
}

body.euiBody--headerIsFixed {
  padding-top: 96px;
}

.euiFlyout.euiFlyout--push.euiFlyout--left {
  top: 48px;
}

.euiHeader.breadcrumb-nav {
  left: 250px;
  @media screen and (max-width: 991px) {
    left: 0;
  }
}

.legacy-page-body {
  padding: 10px;
}

.mtb-side-nav {
  width: 250px !important;

  span.euiListGroupItem__label a {
    display: block;
    color: inherit;
  }

  span.euiListGroupItem__text {
    display: flex;
  }

  .euiListGroupItem__button {
    display: flex;

    .euiListGroupItem__label {
      flex: 1;
    }
  }
}

.mtb-page-header {
  .euiFlexGroup--gutterLarge > .euiFlexItem {
    margin-top: 0;
    margin-bottom: 0;
  }

  .euiPinnableListGroup__itemExtraAction {
    display: none;
  }
}

.mtb-saved-filters {
  .euiContextMenuItem__text {
    display: flex;
  }
}
